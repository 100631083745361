const Roadmap = () => {

  return (
    <section className="featured-game roadmap" id="roadmap">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-heading">
              <h5 className="subtitle" style={{marginTop: '80px'}}>
                Release & Development
              </h5>
              <h2 className="title">
                Roadmap
              </h2>
              <p className="text">
                <small style={{fontSize: '12px', opacity: 0.25}}><i><sup>*</sup>Pssst... HODL your NFTs until New Year's
                  to get a special surprise inside DashLeague!</i></small>
              </p>
            </div>
          </div>
        </div>


        <div className="row justify-content-center">

          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img text-center">
                    <img src="assets/images/nft.gif" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                      Mint 500 Founding NFTs
                    </h4>
                    <p className="text">
                      Fully mint out the first 500 Genesis NFT to the founding community
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/icon_01.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                      Launch $POWER & $PARTS Contracts
                    </h4>
                    <p className="text">
                      Launch game token contracts, release tokens, setup treasury, and fund liquidity pools 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/app.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                      Launch App & Marketplace
                    </h4>
                    <p className="text">
                      Let the race for survival begin...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/mate.gif" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                      Enable Buildable Schematics 
                    </h4>
                    <p className="text">
                      Enable founding <b className="text-yellow">Ballers</b> to build new <b className="text-yellow">Ballers</b> through merging schematics of two parent <b className="text-yellow">Ballers</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="single-box">
              <div className="row justify-content-center">
                <div className="col-lg-3">
                  <div className="img">
                    <img src="assets/images/sun_bg.gif" alt=""/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="content">
                    <h4 className="title">
                      Supernova Event
                    </h4>
                    <p className="text">
                      The great reset, who will survive, who will perish?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>
  )
};

export default Roadmap;
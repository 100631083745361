import {connect} from "react-redux";

const Race = () => {
  return (

    <section className="racebg" id="gameplay-s">
      <div className="container">
        <div className="row">
          <div className="offset-lg-7 col-lg-5 d-flex align-self-center order-first order-md-last order-lg-last">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  Race In Exciting Showdowns
                </h5>
                <h2 className="title">
                  $PARTS & $POWER
                </h2>
                <p className="text">

                  Race in exciting showdowns vs other players in a winner take all race to the end!

                </p>
                <div className="row" style={{marginTop: '10px'}}>
                  <div className="col-lg-3 d-flex align-self-center order-first">
                    <img src="/assets/images/icon_01.png" />
                  </div>
                  <div className="col-lg-9 d-flex align-self-center order-first order-md-last order-lg-last">
                    <p className="text">

                      Win $PARTS whenever you damage or knock another <b className="text-yellow">Ballers</b> out of the race

                    </p>
                  </div>
                </div>
                <div className="row" style={{marginTop: '10px'}}>
                  <div className="col-lg-3 d-flex align-self-center order-first">
                    <img src="/assets/images/icon_03.png" />
                  </div>
                  <div className="col-lg-9 d-flex align-self-center order-first order-md-last order-lg-last">
                    <p className="text align-self-left">

                      Win $POWER when you place 1st, 2nd or 3rd in a race

                    </p>
                  </div>
                </div>
                <div className="row" style={{marginTop: '30px'}}>
                  <div className="col-lg-6 d-flex align-self-center order-first">
                    <a href="#gs-2"
                      className="mybtn1 link2 btn-full"
                    >Mint NFT</a>
                  </div>
                  <div className="col-lg-6 d-flex align-self-center order-first">
                    <a href="https://jp0-1.gitbook.io/pocketballers/" target="_blank"
                      className="mybtn2 link2 btn-full"
                    >White Paper</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </section>
    )
};


function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(Race)
import {connect} from "react-redux";
import MintButton from "./MintButton";
import Timer from "./Timer";

const Survival = ({seconds, setSeconds, setNftRequestStatus}) => {
  return (
    <section className="get-start" id="gs-2" style={{paddingTop: '80px', paddingBottom: '40px'}}>
      <div className="container">
      	<div className="row justify-content-center">
          <div className="col-lg-10 align-self-center">
          	<div className="section-heading">
	          	<h1 className="title">Join The Fight For Survival</h1>
	          	<h2 className="subtitle">Mint One Of Only 500 Founding Ballers</h2>
          	</div>
          </div>
        </div>
        <div className="row" style={{marginTop: '40px'}}>
          <div className="col-lg-5">
            <div className="right-image">
              <img src="assets/images/nft.gif" alt=""/>
            </div>
            <div className="links">
                <MintButton/>
            </div>
          </div>
          <div className="col-lg-7 d-flex align-self-center">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  1 out of 500 remaning
                </h5>
                <h2 className="title ">
                 [insert bar here]
                </h2>

                <p className="text">
                  Founding <b className="text-yellow">Ballers</b> cost 1 ETH and can never be destroyed nor sent to the scrap yard even if you leave their $PARTS at 0 
                </p>
                <br/> <br/>
                <p className="text">
                  Each founding <b className="text-yellow">Ballers</b> has unique DNA and contains unique schematics for building future <b className="text-yellow">Ballers</b> 
                </p>
                <br/> <br/>
                <p className="text">
                  Genesis mint count intentionally set low to reward founding <b className="text-yellow">Ballers</b> holders through the release of future <b className="text-yellow">Ballers</b> 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
)
};


function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(Survival)
import ReactTypingEffect from 'react-typing-effect';
import React, { Component } from 'react'
import { connect } from 'react-redux'

const Loading = ({nftRequestStatus, setNftRequestStatus}) => {

  const {loading, error, success} = nftRequestStatus;

  return(
    <div>
      <div className="preloader" id="preloader">



        {(loading  && (
          <div>
            <h4>Minting Your NFT...</h4>
            <div className="loader loader-1">
              <div className="loader-outter" />
              <div className="loader-inner" />
            </div>
          </div>
        ))}


        {(success && (
          <>
            <h4>Congratulations, You've got a Dasher!</h4>
            <p>This will appear in your opensea account shortly.</p>
            <p style={{margin: '30px'}}>
              <a style={{background: 'lightseagreen'}} className="mybtn1" href="https://opensea.io/account" target="_blank">
                Go To OpenSea
              </a>
            </p>
          </>
        )) }


        {(error && (
          <>
            <h4>There was an error getting your Dasher</h4>
          </>
        )) }


        {(! loading  && <div>
          <a className="close-btn" onClick={()=>setNftRequestStatus({
            success: false,
            loading: false,
            error: false,
          })}>Back To Site</a>
        </div>)}
      </div>
    </div>
  )
}


export default Loading;
import {connect} from "react-redux";
import MintButton from './MintButton';

const Hero = () => {
  return (
    <div className="hero-area" id="top">

      <div className="container">
        <div className="row">
          <div className="offset-lg-6 col-lg-6 d-flex align-self-center">
            <div className="hero-overlay">
              <div className="left-content">
                <div className="content text-center">
                  <h5 className="subtitle">
                    Play To Earn
                  </h5>
                  <img src="assets/images/logo.png" alt="" />
                  <h1 className="title" style={{marginTop: '10px'}}>
                    Race To Survive
                  </h1>
                  <p className="text">
                    PocketBallers is a P2E crypto pinball racing game. Fast action, hyper competitive, unlimited gameplay. Race against other players to survive, send the rest to the scrapyard! 
                  </p>
                  <div className="row" style={{marginTop: '30px'}}>
                    <div className="col-lg-6 d-flex align-self-center order-first">
                      <a href="#gs-2"
                        className="mybtn1 link2 btn-full"
                      >Mint NFT</a>
                    </div>
                    <div className="col-lg-6 d-flex align-self-center order-first">
                      <a href="#learn"
                        className="mybtn2 link2 btn-full"
                      >Learn More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(Hero)
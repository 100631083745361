import Web3 from 'web3'

// The network name to use for minting
const NETWORK_NAME = "Main";

// The chain id of the network. MetaMask needs this to change
// network instead of network name
const CHAIN_ID = '0x1';

export const isCorrectNetwork = (currentNetwork) => {
  return (currentNetwork === NETWORK_NAME);
};

export const requestNetworkChange = async () => {
  const web3 = new Web3(window.ethereum);

  await web3.currentProvider.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: CHAIN_ID}]
  })
};
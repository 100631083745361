import { update } from '../store/interactions'
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Navbar from './Navbar'
import Main from './Main'
import Header from "./Header";
import Footer from "./Footer";

class App extends Component {
  async UNSAFE_componentWillMount() {
    await this.loadBlockchainData(this.props.dispatch)
  }


  async loadBlockchainData(dispatch) {
    /* Case 1, User connect for 1st time */
    if(typeof window.ethereum !== 'undefined'){
      await update(dispatch)
      /* Case 2 - User switch account */
      window.ethereum.on('accountsChanged', async () => {
        await update(dispatch)
      });
      /* Case 3 - User switch network */
      window.ethereum.on('chainChanged', async () => {
        await update(dispatch)
      });
    }
  }

  render() {
    return (
      <>
        <Header />

        <Main />

        <section className="lottery-video" style={{display: 'none'}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="video-box">
                  <a href="https://www.youtube.com/watch?v=8tPnX7OPo0Q"
                     className="video-play-btn video-icon mfp-iframe">
                    <img src="assets/images/play-icon-red.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />

        <a className="bottomtotop" href="#top">
          <i className="fas fa-chevron-right" />
        </a>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(App)
import {connect} from "react-redux";

const GetStartArea = () => {
  return (
    <section className="get-start" id="learn">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="right-image">
              <img src="assets/images/second.gif" alt=""/>
            </div>
          </div>
          <div className="col-lg-7 d-flex align-self-center">
            <div className="left-area">
              <div className="section-heading">
                <h5 className="subtitle">
                  Their planet is dying
                </h5>
                <h2 className="title ">
                  ...and only you can save them!
                </h2>
                <p className="text">
                  Left behind by their creators, <b className="text-yellow">Ballers</b>, are in a race to gather the remaining resources to escape their dying planet 

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
)
};


function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(GetStartArea)
import {connect} from "react-redux";

const Sun = () => {
  return (

	<section className="sunbg" id="gameplay-s">
      <div className="container">
        <div className="row" style={{marginTop: '50px'}}>
          <div className="col-lg-4 d-flex align-self-center order-first order-md-last order-lg-last">
            <div className="left-area">
              <div className="section-heading">
              
                <p className="text">

                  With limited resources remaining the <b className="text-yellow">Ballers</b> fight for the rights to the remaining ore and power in the most action packed survival of the fittest showdown!
                  
                </p>
              </div>
            </div>
          </div>
        </div>
   	</div>
    </section>
    )
};


function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(Sun)
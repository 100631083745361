import React from "react";

const FaqGamePlay = () => {

  return (
    <div className="tab-pane active show" id="gameplay" role="tabpanel" aria-labelledby="gameplay-tab">
      <div className="accordion sorteo-accordion" id="accordionExample2">
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game1"
                      aria-expanded="true" aria-controls="collapseFive"><i
                className="fa fa-question"/> Are our NFTs our playable?
              </button>
            </h2>
          </div>
          <div id="game1" className="collapse show" aria-labelledby="game1"
               data-parent="#accordionExample2">
            <div className="card-body">
              <p>Yes, the <span className="text-orange">NFTs</span> you purchase <span
                className="text-seagreen">are playable</span> in the game and are required to enter races!</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game2"
                      aria-expanded="true" aria-controls="game2"><i className="fa fa-question"/> Do our
                NFTs update based on in-game play?
              </button>
            </h2>
          </div>
          <div id="game2" className="collapse show" aria-labelledby="game2" data-parent="#accordionExample2">
            <div className="card-body">
              <p>Yes! The <span className="text-orange">metadata of your NFT</span> update as you play, and
                will reflect <span className="text-seagreen">wins, losses, and other important stats</span>!
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#game3"
                      aria-expanded="true" aria-controls="game3"><i className="fa fa-question"/> Can NFTs be destroyed?
              </button>
            </h2>
          </div>
          <div id="game3" className="collapse show" aria-labelledby="game3" data-parent="#accordionExample2">
            <div className="card-body">
              <p>Yes, if your NFT is destroyed during a race and you do not use $PARTS to heal it, it will be sent to the scrap yard within 24 hours.
              </p>
            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default FaqGamePlay;
import React from "react";

const FaqGeneral = () => {

  return (
    <div className="tab-pane fade active show" id="general" role="tabpanel" aria-labelledby="general-tab">
      <div className="accordion sorteo-accordion" id="accordionExample">
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#gen1"
                      aria-expanded="true" aria-controls="gen1"><i className="fa fa-question"></i> How will
                PocketBallers be launched?
              </button>
            </h2>
          </div>
          <div id="gen1" className="collapse show" aria-labelledby="headingOne"
               data-parent="#accordionExample">
            <div className="card-body">
              <p>We will be launching it in two phases, a capped 10% pre-sale of 50 and then a public minting
                process shortly after on <a href="#"
                                            className="text-orange">PocketBallers.io</a>. Make sure to join
                the <a href="https://discord.gg/7u26bRyEWC" target="_blank"
                       className="text-seagreen">Discord</a> and to follow us on all our <span
                  className="text-pink">social media outlets</span> to stay up to date on any special
                announcements and giveaways! </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#gen2" aria-expanded="false" aria-controls="gen2"><i
                className="fa fa-question">&nbsp;</i> How much does each PocketBaller NFT cost to mint?
              </button>
            </h2>
          </div>
          <div id="gen2" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div className="card-body">
              <p>Each PocketBaller NFT <span className="text-orange">costs 1 ETH to mint</span>. Once <span
                className="text-seagreen">completely minted</span>, you can buy any future NFTs
                on <a href="https://opensea.io/collection/PocketBallers" target="_blank"
                      className="text-pink">OpenSea</a></p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#gen3" aria-expanded="false" aria-controls="gen3"><i
                className="fa fa-question">&nbsp;</i> Where can I buy NFTs after the mint?
              </button>
            </h2>
          </div>
          <div id="gen3" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div className="card-body">
              <p>Once <span className="text-orange">completely minted</span>, you can buy any future
                 NFTs on <a href="https://opensea.io/collection/PocketBallers" target="_blank"
                                      className="text-seagreen">OpenSea</a>. See our <a href="#roadmap"
                                                                                        target="_blank"
                                                                                        className="text-pink">roadmap</a> for
                additional details on other platforms coming soon!</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#gen4" aria-expanded="false" aria-controls="gen4"><i
                className="fa fa-question">&nbsp;</i> What is the smart contract address for PocketBallers?
              </button>
            </h2>
          </div>
          <div id="gen4" className="collapse show" aria-labelledby="headingThree"
               data-parent="#accordionExample">
            <div className="card-body">
              <p>This will be <span className="text-orange">shared publicly</span> during the <span
                className="text-seagreen">public</span> <span
                className="text-pink">sale/minting period.</span> or in the whitepaper</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#gen5" aria-expanded="false" aria-controls="collapseFour"><i
                className="fa fa-question">&nbsp;</i> Are any NFTs reserved for giveaways and
                promotions?
              </button>
            </h2>
          </div>
          <div id="gen5" className="collapse show" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div className="card-body">
              <p><span className="text-orange">10% of the total mint supply (50)</span> will be <span
                className="text-seagreen">reserved for giveaways and promotions</span>! So make sure you
                follow us on social media and join our <a href="https://discord.gg/7u26bRyEWC"
                                                          target="_blank"
                                                          className="text-pink">Discord</a> channel!</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#gen6" aria-expanded="false" aria-controls="gen5"><i
                className="fa fa-question">&nbsp;</i> Which wallet can I use to mint or play?
              </button>
            </h2>
          </div>
          <div id="gen6" className="collapse show" aria-labelledby="gen5" data-parent="#accordionExample">
            <div className="card-body">
              <p> Currently we only have enabled <span className="text-orange">MetaMask</span> but have
                plans to <span className="text-seagreen">support additional wallets in the future</span>.
                Stay tuned in our community for <span className="text-pink">announcements</span>.

              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#gen7" aria-expanded="false" aria-controls="gen6"><i
                className="fa fa-question"/> How can I contact the PocketBallers team?
              </button>
            </h2>
          </div>
          <div id="gen7" className="collapse show" aria-labelledby="gen5" data-parent="#accordionExample">
            <div className="card-body">
              <p> For all game related issues, <a href="https://discord.gg/7u26bRyEWC" target="_blank"
                                                  className="text-orange">use #support on Discord</a>.
                To <span className="text-seagreen">speak to the developers</span>, you can easily find them
                on from time to time doing AMAs in the <a href="https://discord.gg/7u26bRyEWC"
                                                          target="_blank" className="text-pink">#general
                  Discord</a> channel!

              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FaqGeneral;
import React, {useEffect} from "react";
import {accountSelector, balanceSelector, networkSelector, web3Selector} from "../store/selectors";
import { connect } from 'react-redux'
import {approvedNetIds} from "../store/interactions";
import {isCorrectNetwork, requestNetworkChange} from "../utils/MetaMask";

const ConnectWalletButton = ({account, color, network}) => {

  const style = (color) ? {background: color} : {};

  useEffect(()=>{
    if(! isCorrectNetwork(network) ) {
      requestNetworkChange();
    }
  }, [network])

  if(! isCorrectNetwork(network)) {
    return (
      <button className="mybtn1 link1" style={style} onClick={requestNetworkChange} >Switch Network</button>
    )
  }

  if(account){
    return (
      <button className="mybtn1 link1" style={{background: 'green'}}>Connected!</button>
    )
  }

  return(
    <button onClick={async (e) => {
      e.preventDefault();

      try {
        await window.ethereum.enable()
      } catch (e) {
        console.error(e)
      }
    }} className="mybtn1 link1"     style={style}>Connect Wallet</button>
  )
};


function mapStateToProps(state) {
  return {
    account: accountSelector(state),
    network: networkSelector(state)
  }
}

export default connect(mapStateToProps)(ConnectWalletButton)
import abi from '../abis/PocketBallers.json'
//import Contract from '../backEnd/abis/NFT.json'
import Web3 from 'web3'
import {
  web3Loaded,
  contractLoaded,
  web3NetworkLoaded,
  web3AccountLoaded,
  web3BalanceLoaded,
  metadataLoaded,
} from './actions';

const address = '0x8Ae0d9cE787948D0e3E39e47224b165e90A9E713';

export const loadWeb3 = async (dispatch) => {
  try{
    if(typeof window.ethereum!=='undefined'){
      window.ethereum.autoRefreshOnNetworkChange = false;
      const web3 = new Web3(window.ethereum)
      dispatch(web3Loaded(web3))
      return web3
    }
  } catch (e) {
    console.log('Error, load Web3: ', e)
  }
}

export const loadNetwork = async (dispatch, web3) => {
  try{
    let network = await web3.eth.net.getNetworkType()
    network = network.charAt(0).toUpperCase()+network.slice(1)
    dispatch(web3NetworkLoaded(network))
    return network
  } catch (e) {
    dispatch(web3NetworkLoaded('Wrong network'))
    console.log('Error, load network: ', e)
  }
}

export const loadAccount = async (dispatch, web3) => {
  try{
    const accounts = await web3.eth.getAccounts()
    const account = await accounts[0]
    if(typeof account !== 'undefined'){
      dispatch(web3AccountLoaded(account))
      return account
    } else {
      dispatch(web3AccountLoaded(null))
      return null
    }
  } catch (e) {
    console.log('Error, load account: ', e)
  }
}

export const loadBalance = async (dispatch, web3, account) => {
  try {
    const etherBalance = await web3.eth.getBalance(account)
    dispatch(web3BalanceLoaded((etherBalance/10**18).toFixed(5)))
  } catch (e) {
    console.log('Error, load balance: ', e)
  }
}

export const loadContract = async (dispatch, web3, netId) => {
  try {
    const contract = new web3.eth.Contract(abi, address)
    //const contract = new web3.eth.Contract(Contract.abi, Contract.networks[netId].address)

    dispatch(contractLoaded(contract))
    return contract
  } catch (e) {
    window.alert('Wrong network!')
    console.log('Error, load contract: ', e)
    dispatch(contractLoaded(null))
    return null
  }
}

export const update = async (dispatch) => {

  try{
    let account, web3, netId, contract, network

    web3 = await loadWeb3(dispatch)
    network = await loadNetwork(dispatch, web3)
    account = await loadAccount(dispatch, web3)
    netId = await web3.eth.net.getId()
    contract = await loadContract(dispatch, web3, netId)

    if(account && contract){
      await loadBalance(dispatch, web3, account)
    }
  } catch (e) {
    console.log('Error, update data: ', e)
  }
}


export const buyNft = async (dispatch, amount, setNftRequestStatus) => {

  try{
    const web3 = await loadWeb3(dispatch)
    await loadNetwork(dispatch, web3)
    const account = await loadAccount(dispatch, web3)
    const netId = await web3.eth.net.getId()
    const contract = await loadContract(dispatch, web3, netId)

    const cost = 1 * amount;

    //setNftRequestStatus({
    //  loading: true,
    //  success: false,
    //  error: false,
    //});

    var gasPrice, gas;

    try {
      gasPrice = await web3.eth.getGasPrice();
      // gas = await contract.methods.mint(account, amount).estimateGas({
      //   from,
      //   value: web3.utils.toWei((cost).toString(), "ether")
      // });
      // console.log('Gas Price: ' + gasPrice)
      // console.log('Gas Required: ' + gas)

      // Hard code the gas required here, this allows the insufficent funds
      // message to appear in metamask and not on the website and it provides a
      // better UX to allow users to add funds
      // Mint 1 - 163963
      // Mint 10 - 1203787
      // Mint 20 - 2359148
      switch(amount) {
        case(10):
          gas = 1203787;
          break;
        case(20) :
          gas = 2359148;
          break;
        default :
          gas = 163963;
      }

    }catch(err){
      //setNftRequestStatus({
      //  success: false,
      //  loading: false,
      //  error: false,
      //});
    }
    

    await contract.methods.mint(amount).send({
      from: account,
      value: web3.utils.toWei((cost).toString(), "ether"),
      //gasPrice,
      //gas,
    })
      .on('receipt', async (r) => {
        //update(dispatch)

        //setNftRequestStatus({
        //  success: true,
        //  loading: false,
        //  error: false,
        //});
        console.log(r);
      })
      .on('error',(error) => {
        if(error.code===4001) {
          // user rejected it in meta mask
          //setNftRequestStatus({
          //  success: false,
          //  loading: false,
          //  error: false,
          //});
          return ;
        }

        console.error(error)
        // window.alert(`There was an error!`)
        //setNftRequestStatus({
        //  success: false,
        //  loading: false,
        //  error: true,
        //});
      })


  } catch (e){
    console.log('Error, buy NFT', e)
  }
}
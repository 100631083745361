import React , {useState} from 'react';
import FaqGeneral from "./FaqGeneral";
import FaqTokenomics from "./FaqTokenomics";
import FaqGamePlay from "./FaqGamePlay";
import cx from 'classnames'

const Faqs = () => {

  const [active, setActive] = useState('general')

  return (
    <section className="faq-section" id="faq">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="section-heading">
              <h5 className="subtitle">
                Got Questions?
              </h5>
              <h2 className="title">
                We have got answers!
              </h2>
              <p className="text">
                Click a category below to see the most frequently asked questions
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="faq-wrapper">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a className={cx('nav-link faq-nav-link', { 'active': (active==='general')})} id="general-tab" data-toggle="tab" role="tab"
                     onClick={()=>setActive('general')}
                     aria-controls="general" aria-selected="false">General</a>
                </li>
                <li className="nav-item">
                  <a className={cx('nav-link faq-nav-link', { 'active': (active==='gameplay')})} id="gameplay-tab" data-toggle="tab"  role="tab"
                     onClick={()=>setActive('gameplay')}
                     aria-controls="gameplay" aria-selected="false">Game Play</a>
                </li>
                <li className="nav-item">
                  <a className={cx('nav-link faq-nav-link', { 'active': (active==='tokenomics')})} id="tokenomics-tab" data-toggle="tab" role="tab"
                     onClick={()=>setActive('tokenomics')}
                     aria-controls="tokenomics" aria-selected="false">Tokenomics</a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {(active==='general' && <FaqGeneral />)}
                {(active==='tokenomics' && <FaqTokenomics />)}
                {(active==='gameplay' && <FaqGamePlay />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
export default Faqs;
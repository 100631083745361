import React from "react";

const FaqTokenomics = () => {
  return (
    <div className="tab-pane active show" id="tokenomics" role="tabpanel" aria-labelledby="tokenomics-tab">
      <div className="accordion sorteo-accordion" id="accordionExample3">
        <div className="card">
          <div className="card-header" id="headingSeven">
            <h2 className="mb-0">
              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#token1"
                      aria-expanded="true" aria-controls="collapseSeven"><i
                className="fa fa-question"/> Where is the white paper?
              </button>
            </h2>
          </div>
          <div id="token1" className="collapse show" aria-labelledby="headingSeven"
               data-parent="#accordionExample3">
            <div className="card-body">
              <p>You can find the whitepaper and tokenomics by clicking <a href="https://jp0-1.gitbook.io/pocketballers/" target="_blank">here</a>.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingEight">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token2" aria-expanded="false" aria-controls="collapseEight"><i
                className="fa fa-question"/> Is there a fee to race?
              </button>
            </h2>
          </div>
          <div id="token2" className="collapse show" aria-labelledby="headingEight"
               data-parent="#accordionExample3">
            <div className="card-body">
              <p>No, however racers use $POWER as they race and racers may incur stat penalities if their $POWER gets too low during a race.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token3" aria-expanded="false" aria-controls="token3"><i
                className="fa fa-question"/> Will PocketBallers have it's own marketplace for game assets?
              </button>
            </h2>
          </div>
          <div id="token3" className="collapse show" aria-labelledby="token3" data-parent="#accordionExample3">
            <div className="card-body">
              <p>Yes! PocketBellers will be able to <span
                className="text-orange">trade and buy/sell assets</span> in the <span
                className="text-seagreen">in-game marketplace</span> using <span
                className="text-pink">$PARTS & $POWER</span>. See the roadmap and discord for announcements!
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-link collapsed" type="button" data-toggle="collapse"
                      data-target="#token5" aria-expanded="false" aria-controls="token5"><i
                className="fa fa-question">&nbsp;</i> Can I buy/sell $POWER & PARTS?
              </button>
            </h2>
          </div>
          <div id="token5" className="collapse show" aria-labelledby="token5" data-parent="#accordionExample3">
            <div className="card-body">
              <p>
                Yes! Join the <a href="https://discord.gg/7u26bRyEWC" target="_blank"
                            className="text-orange">Discord</a> to stay <span className="text-seagreen">up to date</span> on
                the <span className="text-pink">planned developments</span> for $POWER & PARTS.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqTokenomics;